export function useConcentrationFn(a275, a325, a355) {
  const getAlpha = () => {
    return 0.07379 * a325 - 0.05156 * a355 - 0.01907 * a275
  }

  const getBeta = () => {
    return 0.0555 * a355 - 0.04759 * a325 + 0.0051 * a275
  }

  const getGamma = () => {
    return 0.08336 * a355 - 0.1574 * a325 + 0.3719 * a275
  }

  return { getAlpha, getBeta, getGamma }
}

export function useRatioFn(alpha, beta, gamma, weight, humidity) {
  const getAlphaRatio = () => {
    return alpha * 2500 / weight * 100 / (100 - humidity)
  }

  const getBetaRatio = () => {
    return beta * 2500 / weight * 100 / (100 - humidity)
  }

  const getGammaRatio = () => {
    return gamma * 2500 / weight * 100 / (100 - humidity)
  }

  return { getAlphaRatio, getBetaRatio, getGammaRatio }
}
