<script setup>
import { ref, reactive } from "vue";
import { ElForm, ElFormItem, ElInput, ElInputNumber, ElSwitch, ElButton, ElRow, ElCol } from "element-plus";

import inputFormRules from "@/components/CalculatorComponent/inputFormRules";
import { useConcentrationFn, useRatioFn } from "@/components/CalculatorComponent/useComputeFn";

const form = reactive({
  weight: null,
  humidityEnable: false,
  humidity: null,
  a275: null,
  a325: null,
  a355: null
})

const result = reactive({
  status: false,
  alpha: null,
  beta: null,
  gamma: null,
  epsilon: null
})

const formRef = ref()

const computeResult = () => {
  const humidity = form.humidityEnable ? form.humidity : 0
  const { getAlpha, getBeta, getGamma } = useConcentrationFn(form.a275, form.a325, form.a355)
  const { getAlphaRatio, getBetaRatio, getGammaRatio } = useRatioFn(
      getAlpha(),
      getBeta(),
      getGamma(),
      form.weight,
      humidity
  )
  result.alpha = getAlphaRatio().toFixed(2)
  result.beta = getBetaRatio().toFixed(2)
  result.gamma = getGammaRatio().toFixed(2)
  result.epsilon = (getAlphaRatio() + getBetaRatio()).toFixed(2)
  result.status = true
}

const onComputeClick = () => {
  formRef.value.validate((status) => {
    if (status) {
      computeResult()
    }
  })
}

const onResetClick = () => {
  formRef.value.resetFields()
  result.status = false
}
</script>

<template>
  <el-form :model="form" :rules="inputFormRules" ref="formRef">
    <div class="input-row">
        <el-form-item label="Масса навески, гр" prop="weight">
          <el-input-number v-model="form.weight"
                           :controls="false"
                           :precision="4" />
        </el-form-item>
        <el-switch v-model="form.humidityEnable"
                   inactive-text="Не учитывать влажность"
                   active-text="Указать влажность, %"/>
        <el-form-item v-if="form.humidityEnable" prop="humidity">
          <template #label>
            <span id="humidityLabel">Влажность</span>
          </template>
          <el-input-number v-model="form.humidity"
                           :controls="false"
                           :precision="2" />
        </el-form-item>
    </div>
    <div class="input-row pad">
      <el-form-item prop="a275">
        <template #label>
          <p>A<sub>275</sub></p>
        </template>
        <el-input-number v-model="form.a275"
                         :controls="false"
                         :precision="4" />
      </el-form-item>
      <el-form-item prop="a325">
        <template #label>
          <p>A<sub>325</sub></p>
        </template>
        <el-input-number v-model="form.a325"
                         :controls="false"
                         :precision="4" />
      </el-form-item>
      <el-form-item prop="a355">
        <template #label>
          <p>A<sub>355</sub></p>
        </template>
        <el-input-number v-model="form.a355"
                         :controls="false"
                         :precision="4" />
      </el-form-item>
    </div>
    <el-button @click="onResetClick">Сброс</el-button>
    <el-button type="primary" @click="onComputeClick">Расчет</el-button>
  </el-form>
  <div class="input-row" v-if="result.status">
    <el-input v-model="result.alpha" readonly>
      <template #prepend>С&alpha; =</template>
    </el-input>
    <el-input v-model="result.beta" readonly >
      <template #prepend>С&beta; =</template>
    </el-input>
    <el-input v-model="result.gamma" readonly>
      <template #prepend>С&gamma; =</template>
    </el-input>
  </div>
  <el-row v-if="result.status">
    <el-col :sm="8" :xs="24" id="epsilonValue">
      <el-input v-model="result.epsilon" readonly>
        <template #prepend>С&Sigma; =</template>
      </el-input>
    </el-col>
  </el-row>
</template>

<style>
.input-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.input-row .el-input-number {
  height: fit-content;
}
.input-row.pad {
  padding-left: 6rem;
}
#humidityLabel {
  display: none;
}
#epsilonValue {
  padding-right: 10px;
}
@media all and (max-width: 600px) {
  .input-row {
    flex-direction: column;
    align-items: flex-end;
  }
  .input-row:first-child {
    padding-bottom: 1rem;
  }
  #humidityLabel {
    display: inline;
  }
  #epsilonValue {
    padding-right: 0;
  }
}
</style>
