export default {
  weight: [{
    required: true,
    message: 'Введите значение'
  }],
  humidity: [{
    required: true,
    message: 'Введите значение'
  }],
  a275: [{
    required: true,
    message: 'Введите значение'
  }],
  a325: [{
    required: true,
    message: 'Введите значение'
  }],
  a355: [{
    required: true,
    message: 'Введите значение'
  }]
}
