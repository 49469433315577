<template>
    <p>
      На аналитических весах отвешивается примерно 2.5 гр сырья (точная навеска), переносится в колбу, заливается 50 мл петролейного эфира,
      встряхивается на шейкере и фильтруется.
    </p>
    <p>
      Далее из получившейся смеси выбирается 50 мкл (0.05 мл) экстракта, переносится в колбу на 25 мл и доводится до необходимого объема
      щелочным раствором метанола, после чего весь объем заливается в ячейку спектрофотометра и производятся измерения спектра в ультрафиолетовой области.
    </p>
    <p>
      Результаты измерений - три значения оптической плотности при длинах волн 275, 325 и 355 нм
      обозначаются как a<sub>275</sub>, a<sub>325</sub> и a<sub>355</sub>
    </p>
</template>
